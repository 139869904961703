<template>
  <div class="myReefer-cart-item flex items-baseline justify-between mb2 gap2">
    <div class="flex lg-items-center items-center gap2">
      <!-- Amount Select -->
      <div :class="`myReefer-product-card-amount-select-wrapper relative ${product.hasFixedAmount == '1' ? 'inactive-input' : ''}`" style="margin: 0">
        <svg :class="`chevron svg-color-1`" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <select :class="`myReefer-product-card-amount-select h4 open-sans-bold mx-auto pointer bg-color-white color-1`" v-model="displayAmount" @change="checkIfYouCanSelectproduct()">
          <option :value="0">0</option>
          <option v-for="index in Number(product.maxAmountPerItem)" :value="index">{{ index }}</option>
          <!-- <option :value="2">2</option> -->
        </select>
      </div>
      <div class="lg-flex overflow-hidden flex-wrap items-baseline cart-item-name">
        <h3 class="open-sans-regular h5 nowrap mr1">{{ product.Model }}</h3>
        <h4 class="h6 open-sans-regular color-13 nowrap">
          <span class="color-black open-sans-regular cart-item-info-divider">{{ product.SubHeadline ? " | " : "" }}</span
          >{{ product.SubHeadline ? product.SubHeadline : "" }}
        </h4>
      </div>
    </div>

    <div v-show="selectedCountry.isocode2 != 'XX'">
      <ItemPrice v-if="bundleHead.has_discount != '1'" :currency="product.prices.currency" :discountEvent="discountEvent" :discountEventActive="discountEventActive" :isDiscounted="product.prices.isDiscounted" :discountPrice="product.prices.discountprice" :retailPrice="product.prices.retailprice" :formatCurrency="formatCurrency" :amount="product.amount" />
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import ItemPrice from "./ItemPrice.vue";

export default {
  name: "CartItem",
  props: {
    product: Object,
    selectedProducts: Array,
    updateSelectedProducts: Function,
    formatCurrency: Function,
    productCategories: Array,
    openModal: Function,
    productCategories: Array,
    computeImageOverlays: Function,
    selectedCountry: Object,
    category: Object,
    discountEventActive: Boolean,
    discountEvent: Object,
    bundleHead: Object,
  },
  components: {
    ItemPrice,
  },
  data() {
    return {
      displayAmount: 0,
    };
  },
  created() {
    EventBus.$on("update_amount", (payload) => {
      if (payload.RNumber == this.product.RNumber) {
        this.displayAmount = payload.amount;
      }
    });
  },
  mounted() {
    this.displayAmount = this.product.amount;
  },
  methods: {
    checkIfYouCanSelectproduct() {
      this.checkAmountDepend();
      if (!this.checkAmountDepend) {
        return;
      }
      this.checkForMaxProductQuantityPerCategory();
      if (this.product.resetQuantityAmountOfOtherProductsActive) {
        this.checkIfOtherProductsHaveToBeRemoved();
      }
      this.checkForDividersAndCards();
    },
    checkAmountDepend() {
      let linkedProducts = [];

      //if product is not linked to other product, maybe other prdoucts in cart are linked to this one
      if (!this.product.amountDepend && this.selectedProducts.length > 0) {
        let removeCounter = 0;

        //check if a product in the cart has a connection to the new one
        for (let i = 0; i < this.selectedProducts.length; i++) {
          if (!this.selectedProducts[i].amountDepend) {
            continue;
          }

          if (this.product.RNumber == this.selectedProducts[i].amountDependOnRNumber && this.selectedProducts[i].amount > this.displayAmount) {
            //prdoucts are linked and new product has bigger amount than product in cart, so remove it from cart

            this.selectedProducts[i].amount = this.displayAmount;
            if (this.selectedProducts[i].amount == 0) {
              this.selectedProducts.splice(i, 1);
            } else {
              EventBus.$emit("update_amount", {
                RNumber: this.selectedProducts[i].RNumber,
                amount: this.displayAmount,
              });
            }

            this.$forceUpdate();
            removeCounter++;
          }

          if (removeCounter == 1) {
            this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_product_card_dependAmount_alert_3"));
          }

          if (removeCounter > 1) {
            this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_product_card_dependAmount_alert_3_plural"));
          }
        }
      }

      //loop through selected prdoucts and see if there is a linked product
      for (let x = 0; x < this.selectedProducts.length; x++) {
        if (this.product.amountDependOnRNumber == this.selectedProducts[x].RNumber) {
          linkedProducts.push(this.selectedProducts[x]);
        }
      }

      //if no linked product was found, it is not allowed to select this product
      if (linkedProducts.length == 0 && this.product.amountDepend) {
        this.displayAmount = this.product.amount;
        this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_product_card_dependAmount_alert_2"));
        return false;
      }

      //now loop through linkedProducts and check if conditions meet
      for (let y = 0; y < linkedProducts.length; y++) {
        if (this.displayAmount <= linkedProducts[y].amount) {
          // everything is alright, move on
          continue;
        }

        //displayAmount is higher than the linked prdouct amount, throw a notice
        this.displayAmount = this.product.amount;
        this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_product_card_dependAmount_alert_1"));
        return false;
      }

      //everything is alright!
      return true;
    },
    checkForMaxProductQuantityPerCategory() {
      let category = {};
      for (let i = 0; i < this.productCategories.length; i++) {
        if (this.product.Category == this.productCategories[i].categoryname) {
          category = this.productCategories[i];
        }
      }
      //looping through procut categories

      //everything is fine, just update
      if (category.limitType == "unlimited") {
        this.product.amount = this.displayAmount;
        this.updateSelectedProducts(this.product);
        return;
      }

      if (category.limitType == "and-limit" || category.limitType == "or-limit") {
        let totalProductsAmount = 0;
        for (let x = 0; x < this.selectedProducts.length; x++) {
          //if our product is already in the selctedProducts array, dont take it into account
          // if prdouct is in category, add its amount to totalprdouctsamount
          if (this.selectedProducts[x].id != this.product.id && this.selectedProducts[x].Category == category.categoryname) {
            totalProductsAmount = totalProductsAmount + this.selectedProducts[x].amount;
          }
        }
        totalProductsAmount = totalProductsAmount + this.displayAmount;

        if (totalProductsAmount > category.limitAmount) {
          this.displayAmount = this.product.amount;
          //open modal
          this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_product_card_maxAmount_alert_1"));
        } else {
          this.product.amount = this.displayAmount;
          this.updateSelectedProducts(this.product);
        }
      }

      //this.updateSelectedProducts(product)
    },
    checkIfOtherProductsHaveToBeRemoved() {
      //check if this product is seelcted

      let productsIsSelected = false;
      let productsKickedOut = 0;

      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.product.RNumber == this.selectedProducts[i].RNumber) {
          productsIsSelected = true;
        }
      }

      if (!productsIsSelected) {
        return;
      }

      for (let x = 0; x < this.product.resetQuantityAmountOfOtherProductsRNumbers.length; x++) {
        for (let z = 0; z < this.selectedProducts.length; z++) {
          if (this.product.resetQuantityAmountOfOtherProductsRNumbers[x] == this.selectedProducts[z].RNumber) {
            if (this.product.resetQuantityAmountOfOtherProductsRNumbers[x].amount == this.selectedProducts[z].amount) this.selectedProducts.splice(z, 1);
            this.computeImageOverlays();
            this.$forceUpdate();
            productsKickedOut++;
          }
        }
      }

      if (productsKickedOut > 0) {
        this.openModal(this.$t("rsf_popup_alert_notice"), this.$t("rsf_popup_alert_resetAmount_1"));
      }
    },
    checkForDividersAndCards() {
      for (let z = 0; z < this.category.products.length; z++) {
        if (this.category.products[z].hideProductStart == "1") {
          this.category.products[z].hideProductStartCurrentStatus = "1";
        }
      }
      for (let n = 0; n < this.category.dividerElements.length; n++) {
        if (this.category.dividerElements[n].hideDividerStart == "1") {
          this.category.dividerElements[n].showDivider = "0";
        }
      }

      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].toggleClass != "1") {
          continue;
        }
        for (let x = 0; x < this.category.products.length; x++) {
          if (this.category.products[x].hideCSSClasses == this.selectedProducts[i].selectedClasses) {
            // wenn target-class gleich der hideClass ist, toggle element
            if (this.category.products[x].hideProductStartCurrentStatus == "1") {
              this.category.products[x].hideProductStartCurrentStatus = "0";
            }
          }
        }
        for (let y = 0; y < this.category.dividerElements.length; y++) {
          if (this.category.dividerElements[y].hideCSSClasses == this.selectedProducts[i].selectedClasses) {
            this.category.dividerElements[y].showDivider = "1";
          }
        }
      }
    },
  },
};
</script>
