<template>
  <MyReeferConfiguratorLayout>
    <Loader v-if="selectedTank.mainProductPrices.isDiscounted" slot="loader" :isLoading="!fetchedDetailedData" :loaderHeading="$t('rsf_loader_heading') + ' ' + selectedTank.mainProductInfo.Model" />

    <div slot="top-banner">
      <DiscountEventBannerSmall v-if="discountEventActive" :discountEvent="discountEvent" />
    </div>

    <div slot="mobile-head" class="myreefer-core-mobile-heading relative">
      <h2 class="center open-sans-bold color-black pt3 h2 md-h3" v-if="fetchedDetailedData && selectedTank != {} && bundleHead.has_discount != '1'" style="line-height: 1.2">{{ $t("rsf_core_build_your") }} <br />{{ selectedTank.mainProductInfo.Model }}</h2>
      <div ref="cart" class="mobile-cart w-fit-content" v-show="bundleHead && bundleHead.has_discount != '1'">
        <CartIcon v-show="showCart" :selectedProducts="selectedProducts" :selectedTank="selectedTank" />
      </div>
    </div>

    <DynamicTank slot="left-column-aquarium-preview" :selectedProducts="selectedProducts" :selectedColor="selectedColor" :selectedTank="selectedTank" :imageOverlays="imageOverlays" :loadingImages="loadingImages" :fetchedDetailedData="fetchedDetailedData" />

    <p slot="left-column-foot" class="myReefer-terms-and-cons-line center color-13 open-sans-regular my3">
      {{ $t("rsf_myReeferCore_act") }}
      <a class="color-1" :href="'https://g1.redseafish.com/' + this.siteLanguage + '/terms-and-conditions-myreefer-promotion/'">
        {{ $t("rsf_myReeferCore_terms") }}
      </a>
    </p>

    <div slot="right-column-head">
      <div v-if="bundleHead && bundleHead.has_discount != '1'" class="prdouct-section-head">
        <div class="myReefer-product-section-head flex justify-between items-center border-box col-12 pl3 pr4 py2">
          <h2 class="h2 md-h3 open-sans-bold col-12">{{ fetchedDetailedData ? $t("rsf_core_build_your") : "" }} {{ fetchedDetailedData ? selectedTank.mainProductInfo.Model : "" }}</h2>
          <CartIcon :selectedProducts="selectedProducts" :selectedTank="selectedTank" :toggleCart="toggleCart" />
        </div>
      </div>
      <div v-if="bundleHead && bundleHead.has_discount == '1'">
        <div class="myReefer-product-section-head myReefer-discount-product-section-head flex justify-between items-center border-box flex-wrap col-12 lg-pl3 pl2 py2 lg-pr3 lt-pb1" style="min-height: auto">
          <h2 class="h4 md-h3 open-sans-bold mr2">{{ fetchedDetailedData ? $t("rsf_configurator_heading_your") : "" }} {{ fetchedDetailedData ? selectedTank.mainProductInfo.Model : "" }}</h2>
        </div>
      </div>

      <div v-if="fetchedDetailedData && bundleHead.has_discount != '1'" class="h1px col-12 bg-color-12"></div>
    </div>

    <div slot="overflow-container-inner" class="myReefer-product-section lg-px3 lg-pr3 px0 lg-pb3">
      <div v-if="bundleHead && bundleHead.has_discount == '1'" class="px2 lg-px0">
        <div class="lg-flex items-center mb1">
          <h3 class="open-sans-regular h5 lt-h6">{{ selectedTank.BundleName }}</h3>
          <h4 class="h6 lg-h5 open-sans-regular regular color-13" v-html="selectedColor ? `<span class='px1 color-black open-sans-regular cart-item-info-divider divider-stay'>|</span>` + (selectedColor == 'Black' ? $t('rsf_myReeferCore_colorBlack') : $t('rsf_myReeferCore_colorWhite')) : ''"></h4>
        </div>

        <PlatinumCartItem v-for="product in selectedProducts" :key="product.id" :product="product" />

        <!-- margin-top 32 -> 16 -->
        <div class="mt3 lt-mt2 flex items-baseline justify-between" v-if="selectedCountry.isocode2 != 'XX'">
          <h4 class="h4 open-sans-bold">{{ $t("rsf_total") }}</h4>
          <span class="flex gap1 items-baseline">
            <span class="h5 color-6 crossed">{{ subTotalPreselected }}</span>
            <span class="h4 open-sans-bold">{{ totalPreselected }}</span>
          </span>
        </div>
        <div class="h1px col-12 bg-color-12 my3 lt-my2"></div>
        <h3 class="mb2 lg-h3 h2">{{ $t("rsf_heading_optional_add_ons") }}</h3>
      </div>
      <ProductSection v-if="fetchedDetailedData" :selectedProducts="selectedProducts" :category="cabinetColorCategory" :selectedColor="selectedColor" :showSelectedColor="true" class="myReefer-product-section-cabinet-color" :discountEventActive="discountEventActive" :discountEvent="discountEvent" :bundleHead="bundleHead">
        <CabinetColorCard v-for="product in cabinetColorCategory.products" :key="product.id" :product="product" :selectedColor="selectedColor" :updateCabinetColor="updateCabinetColor" :siteLanguage="siteLanguage" />
      </ProductSection>
      <ProductSection v-if="fetchedDetailedData && category.categoryname != 'Preselected Products'" v-for="category in productCategories" :key="category.category_id" :category="category" :thumbnail="category.categoryimage_s3path" :selectedProducts="selectedProducts" :formatCurrency="formatCurrency" :selectedCountry="selectedCountry" :discountEventActive="discountEventActive" :discountEvent="discountEvent" :bundleHead="bundleHead">
        <ProductCard v-for="(product, index) in category.products" :key="product.products_id" :selectedProducts="selectedProducts" :updateSelectedProducts="updateSelectedProducts" :product="product" :formatCurrency="formatCurrency" :productCategories="productCategories" :openModal="openModal" :category="category" :index="index" :computeImageOverlays="computeImageOverlays" :selectedColor="selectedColor" :selectedCountry="selectedCountry" :cardIndex="index" :siteLanguage="siteLanguage" :discountEventActive="discountEventActive" :discountEvent="discountEvent" />
        <!-- NEW DIVIDERS -->
        <div v-for="(divider, index) in category.dividerElements" v-if="category.products.length > divider.moduloNumber" v-show="divider.showDivider == '1'" :key="index" class="mb2 mt3 h3 open-sans-bold" :style="`grid-row: ${divider.moduloNumber}; grid-column: 1 / 3`">
          {{ divider.name }}
        </div>
      </ProductSection>
    </div>

    <Total slot="right-column-foot" :selectedProducts="selectedProducts" :selectedTank="selectedTank" :selectedColor="selectedColor" :toggleSendCopyForm="toggleSendCopyForm" :toggleSendToDealerForm="toggleSendToDealerForm" :formatCurrency="formatCurrency" :selectedCountry="selectedCountry" :discountEventActive="discountEventActive" :discountEvent="discountEvent" :bundleHead="bundleHead" :total="total" :subTotal="subTotal" :myReeferDiscountAmount="myReeferDiscountAmount" :myReeferDiscountAmountUnformatted="myReeferDiscountAmountUnformatted" :eventDiscountAmount="eventDiscountAmount" :totalSavings="totalSavings" :isDealerVersion="isDealerVersion" />
  </MyReeferConfiguratorLayout>
</template>

<script>
import MyReeferConfiguratorLayout from "./MyReefer_Configurator_Layout.vue";
import CartIcon from "./CartIcon.vue";
import ProductSection from "./ProductSection.vue";
import ProductCard from "./ProductCard.vue";
import CabinetColorCard from "./CabinetColorCard.vue";
import Total from "./Total.vue";
import DynamicTank from "./DynamicTank.vue";
import DiscountEventBannerSmall from "./DiscountEventBannerSmall.vue";
import PlatinumCartItem from "./PlatinumCartItem.vue";
import Loader from "./Loader.vue";

export default {
  name: "MyReeferConfigurator",
  components: {
    MyReeferConfiguratorLayout,
    CartIcon,
    ProductSection,
    ProductCard,
    CabinetColorCard,
    Total,
    DynamicTank,
    DiscountEventBannerSmall,
    PlatinumCartItem,
    Loader,
  },
  props: {
    selectedProducts: Array,
    selectedTank: Object,
    toggleCart: Function,
    updateSelectedProducts: Function,
    toggleSendCopyForm: Function,
    toggleSendToDealerForm: Function,
    updateCabinetColor: Function,
    formatCurrency: Function,
    updateSelectedTank: Function,
    fetchedDetailedData: Boolean,
    fetchProductCategories: Function,
    updateProductsInCategories: Function,
    productCategories: Array,
    cabinetColorCategory: Object,
    selectedColor: String,
    imageOverlays: Array,
    loadingImages: Boolean,
    loadingProducts: Boolean,
    openModal: Function,
    computeImageOverlays: Function,
    selectedCountry: Object,
    resetTank: Function,
    resetSelectedProducts: Function,
    resetImgOverlays: Function,
    siteLanguage: String,
    bundleHead: Object,
    discountEventActive: Boolean,
    discountEvent: Object,
    isDealerVersion: String,

    total: String,
    subTotal: String,
    totalSavings: String,
    totalPreselected: String,
    subTotalPreselected: String,
    myReeferDiscountAmount: String,
    myReeferDiscountAmountUnformatted: Number,
    eventDiscountAmount: String,
  },
  data() {
    return {
      showCart: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    if (!this.selectedTank.id) {
      this.$router.push("/");
    }
    window.scrollTo(0, 0);
    this.fetchProductCategories();
    setTimeout(() => {
      if (!Object.hasOwn(this.selectedTank, "mainProductPrices")) {
        return;
      }
      if (!Object.hasOwn(this.selectedTank.mainProductPrices, "isDiscounted")) {
        return;
      }
      if (!this.selectedTank.mainProductPrices.isDiscounted) {
        this.showCart = true;
        this.stickyCart(this.$refs.cart);
      }
    }, 10);
  },
  methods: {
    stickyCart(cart) {
      let cartDim = cart.getBoundingClientRect();
      let marginTop = cartDim.top;

      addEventListener("scroll", () => {
        if (document.documentElement.scrollTop >= marginTop) {
          cart.classList.add("fixed-cart");
        } else {
          cart.classList.remove("fixed-cart");
        }
      });
    },
    isInSelectedProducts(product) {
      let result = this.selectedProducts.find(({ RNumber }) => RNumber === product.RNumber);
      if (result) {
        return true;
      }
      return false;
    },
    hasSelectedItems(category) {
      let counter = 0;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        let result = category.products.find(({ RNumber }) => RNumber === this.selectedProducts[i].RNumber);
        if (result) {
          counter++;
        }
      }
      if (counter > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
