<template>
  <div class="rsf-select-wrapper myReefer-country-select relative z1">
    <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <select @change="updateCountry(selectedCountry)" v-model="selectedCountry" :style="`${Object.keys(selectedCountry).length == 0 ? 'color: var(--rsf-2022-color-13) !important;' : ''}`" class="rsf-select color-black pointer open-sans-regular">
      <option v-if="!useGeneralCountriesList" isselected disabled>{{ $t("rsf_country_select_prompt") }}</option>
      <option v-if="useGeneralCountriesList" isselected disabled :value="{}">{{ $t("rsf_country_select_prompt") }}</option>
      <option v-for="country in countries" :key="country.id" :value="country">{{ country.countryname }}</option>
    </select>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CountrySelect",
  props: {
    updateCountry: Function,
    siteLanguage: String,
    useGeneralCountriesList: Boolean,
    selectedCountry2: Object,
    myReeferSelectedCountry: Object,
  },
  data() {
    return {
      countries: [],
      selectedCountry: {},
    };
  },
  created() {},
  mounted() {
    let that = this;
    let url = "";
    if (this.useGeneralCountriesList) {
      url = process.env.VUE_APP_RSF_API + "/public-api/list-countries/json";
    } else {
      url = process.env.VUE_APP_RSF_API + "/public-api/list-countries/myreefer/json";
    }
    axios
      .post(url, { lang: this.siteLanguage })
      .then(function (response) {
        that.countries = response.data.data;
        // that.selectedCountry = response.data.data[0]

        for (let i = 0; i < that.countries.length; i++) {
          if (that.countries[i].isocode2 == "XX") {
            let otherCountries = that.countries[i];
            that.countries.splice(i, 1);
            that.countries.push(otherCountries);
          }
        }
        if (!that.useGeneralCountriesList) {
          that.preSelectCountry();
        }
        if (that.useGeneralCountriesList) {
          that.checkForPreselectedCountry();
        }
      })
      .catch(function (error) {
        // window.alert('Ooops... something wnet wrrong here! We are sorry.');
      });
  },
  methods: {
    checkForPreselectedCountry() {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].isocode2 == this.myReeferSelectedCountry.isocode2) {
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
        }
      }
    },
    preSelectCountry() {
      if (this.useGeneralCountriesList) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode2 == this.selectedCountry2.isocode2) {
            this.selectedCountry = this.countries[j];
          }
        }
        return;
      }

      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          return;
        }
      }

      this.findCountry("XX");

      // if (this.$i18n.locale == 'de') {
      //   this.findCountry('DE');
      // }
      // else if (this.$i18n.locale == 'fr') {
      //   this.findCountry('FR');
      // }
      // else if (this.$i18n.locale == 'en') {
      //   this.findCountry('US');
      // }
      // else if (this.$i18n.locale == 'br') {
      //   this.findCountry('US')
      // }
      // else if (this.$i18n.locale == 'cn') {
      //   this.findCountry('US')
      // }
      // else if (this.$i18n.locale == 'ja') {
      //   this.findCountry('US')
      // }else{
      //   this.findCountry('XX');
      // }
    },
    findCountry(isocode) {
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.selectedCountry = this.countries[a];
          this.updateCountry(this.countries[a]);
        }
      }
    },
  },
  watch: {
    myReeferSelectedCountry() {
      if (this.useGeneralCountriesList) {
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].isocode2 == this.myReeferSelectedCountry.isocode2) {
            this.selectedCountry = this.countries[i];
            this.updateCountry(this.countries[i]);
          }
        }
      }
    },
  },
};
</script>
